<template>
  <div>{{ message }}</div>
</template>

<script>
import axios from "axios";
export default {
  name: "ResetMfa",
  props: ["userId", "key"],
  data() {
    return {
      message: "Your device is being reset",
    };
  },
  mounted() {
    const success =
      "Your device was reset successfully. Please login to setup a new MFA device";
    const error =
      "We couldn't verify your reset code. The reset code may have been invalid or expired. " +
      "Please contact the Organisation Administrator that provided you with the link to obtain a new reset code";

    axios
      .get("/api/v1/users/" + this.userId + "/mfa/" + this.key)
      .then(() => (this.message = success))
      .catch(() => (this.message = error));
  },
};
</script>
